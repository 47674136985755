<template>
  <div>
    <b-container class="mt-5 mb-5">
      <b-row>
        <b-col lg="4">
          <b-card no-body class="mb-4 h-lg-100 card-scroll">
            <b-card-header>
              <h5 class="fw-light mb-0">Jouw berichten</h5>
            </b-card-header>
            <b-card-body class="pt-0 pb-0">
              <SupportTicketsMenu />
            </b-card-body>
            <b-card-footer>
              <b-button to="/manage/support/new" variant="primary" block>
                Ticket openen
              </b-button>
            </b-card-footer>
          </b-card>

        </b-col>
        <b-col lg="8">
          <b-card no-body>
            <b-card-header>
              <h5 class="fw-light mb-0">Bericht</h5>
            </b-card-header>
            <b-card-body>
              <ValidationObserver ref="form1" slim>
                <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                  <div class="mb-3">
                    <label>Onderwerp</label>
                    <b-form-input v-model="form.subject" :class="errors.length > 0 ? 'is-invalid' : null" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
                <ValidationProvider rules="required|min:3" v-slot="{ errors }">
                  <div class="mb-3">
                    <label>Bericht</label>
                    <b-form-textarea v-model="form.message" style="min-height:150px;" :class="errors.length > 0 ? 'is-invalid' : null" />
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
                <b-row>
                  <b-col lg="4">
                    <b-button to="/manage/support" variant="secondary" block class="mb-2">
                      <font-awesome-icon icon="fa-solid fa-chevron-left" />  
                      Terug
                    </b-button>
                  </b-col>
                  <b-col lg="8">
                    <b-button @click="createTicket" variant="primary" block class="mb-2">
                      Versturen
                    </b-button>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    import SupportTicketsMenu from '@/components/SupportTicketsMenu'
  
    export default {
        metaInfo: {
            title: 'Ticket Aanmaken',
        },
        components: {
            SupportTicketsMenu,
            ValidationObserver,
            ValidationProvider,
        },
        data() {
            return {
              new_ticket: false,
              form: {
                subject: null,
                message: null,
              },
            }
        },
        computed: {},
        methods: {
          startTicket: function() {
            this.new_ticket = true;
          },
          createTicket: function() {

            let check = this.$refs['form1'].validate();

            if (!check) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
              
              return;
            }

            this.$axios.post("https://api.tixgo.nl/tickets", this.form)
              .then(response => {
                  if (response.data.status == 'success') {
                    let ticket = response.data.ticket;

                    if (ticket) {

                      this.form.ticket = ticket.id;

                      this.$axios.post("https://api.tixgo.nl/messages", this.form)
                        .then(response => {
                            if (response.data.status == 'success') {
                              let message = response.data.message;
                            
                              if (message) {
                                this.$router.push(`/manage/support/item/` + ticket.id + `#thankyou`);
                              }
                            }
                        }
                      );
                    }
                  }
              }
            );
          },
        },
        created() {}
    }
</script>
  
<style scoped>
  .support-item{
    border-bottom:1px solid #eee;
  }
</style>