<template>
    <div>
        <b-alert v-if="!tickets.length" variant="secondary" class="p-2 mt-2 mb-2" show>
            Géén tickets gevonden.
        </b-alert>
        <div class="support-item" v-for="item in tickets" :key="item.id">
            <router-link :to="`/manage/support/item/` + item.id" class="d-block pt-3 pb-3 text-primary text-decoration-none">
                <font-awesome-icon icon="fa-solid fa-globe" class="mr-2 product-icon text-primary" />
                {{ item.subject }}
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
              tickets: [],
            }
        },
        computed: {},
        methods: {
          getTickets: function() {
            this.$axios.get("https://api.tixgo.nl/tickets")
              .then(response => {
                  if (response.data.status == 'success') {
                      this.tickets = response.data.tickets;
                  }
              }
            );
          },
        },
        created() {
          this.getTickets();
        }
    }
</script>
  
<style scoped>
  .support-item{
    border-bottom:1px solid #eee;
  }
</style>